import React from 'react';

import FacebookIcon from 'images/svg/IconFacebook.svg';
import InstagramIcon from 'images/svg/IconInstagram.svg';
import TwitterIcon from 'images/svg/IconTwitter.svg';
import LinkedInIcon from 'images/svg/IconLinkedIn.svg';
import PinterestIcon from 'images/svg/IconPinterest.svg';
import MailIcon from 'images/svg/IconMail.svg';
import SMSIcon from 'images/svg/IconSMS.svg';

const shareLinks = [
  {
    icon: <FacebookIcon />,
    link: '//facebook.com/funeralmarketplace/'
  },
  {
    icon: <InstagramIcon />,
    link: '//instagram.com/funeralmarketplace/'
  },
  {
    icon: <TwitterIcon />,
    link: '//twitter.com/intent/tweet?via=funeral_market&text=about.convela.cloud'
  },
  {
    icon: <LinkedInIcon />,
    link: '//linkedin.com/shareArticle?mini=true&url=about.convela.cloud'
  },
  {
    icon: <PinterestIcon />,
    link: '//pinterest.com/pin/create/button/?url=about.convela.cloud'
  },
  {
    icon: <MailIcon />,
    link: 'mailto:?&body=about.convela.cloud'
  },
  {
    icon: <SMSIcon />,
    link: 'sms:?&body=about.convela.cloud'
  }
];

export default shareLinks;
