import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import 'aos/dist/aos.css';

import contactsImage from '../../images/contactsImage.png';
import phoneImage from '../../images/phone_icon.png';
import chatImage from '../../images/chat_icon.png';
import emailImage from '../../images/email_icon.png';

import styles from './Contacts.module.scss';

const Contacts = () => {
  return (
    <div className={styles.contactsWrapper}>
      <div className={styles.upToTabletPortrait} data-aos="fade" data-aos-offset="100">
        <Parallax y={[-10, 10]} styleOuter={{ marginRight: '42px' }}>
          <img className={styles.contactsImage} src={contactsImage} alt="Kontakt" />
        </Parallax>
      </div>
      <div className={styles.contactsContainer}>
        <div className={styles.contact}>
          <img src={phoneImage} alt="Telefon" />
          <a href="tel:004934577923807">+49 (0) 345 77923807</a>
        </div>
        <div className={styles.contact}>
          <img src={chatImage} alt="Live-Chat" />
          <span>Live-Chat</span>
        </div>
        <div className={styles.contact}>
          <img src={emailImage} alt="Email" />
          <a href="mailto:office@convela.de">office@convela.de</a>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
