import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import PropTypes from 'prop-types';

import BackToTopIcon from '../../images/svg/BackToTopIcon.svg';

import styles from './BackToTopButton.module.scss';

const BackToTopButton = ({ link }) => {
  return (
    <AnchorLink to={link}>
      <div className={styles.backToTopButton}>
        <BackToTopIcon />
        <p>nach oben</p>
      </div>
    </AnchorLink>
  );
};

BackToTopButton.propTypes = {
  link: PropTypes.string
};

export default BackToTopButton;
