import React from 'react';
import PropTypes from 'prop-types';
import BackToTopButton from 'components/BackToTopButton';

import recommendImage from '../../images/recommend_icon.png';

import infoSectionColumns from '../../constants/infoSectionColumns';
import shareLinks from '../../constants/shareLinks';

import styles from './InfoSection.module.scss';

const InfoSection = ({ backToToplink }) => {
  return (
    <div className={styles.infoSection}>
      {infoSectionColumns.map((column) => (
        <div className={styles.infoColumn} key={column.title}>
          <div className={styles.infoTitle}>
            {column.image}
            <span>{column.title}</span>
          </div>
          <div className={styles.infoLinks}>
            {column.links.map((item) =>
              item.link ? (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={item.title}
                >
                  {item.title}
                </a>
              ) : (
                <span key={item.title}>{item.title}</span>
              )
            )}
          </div>
        </div>
      ))}
      <div className={styles.infoColumn}>
        <div className={styles.infoTitle}>
          <img src={recommendImage} style={{ height: 26 }} alt="Seite empfehlen" />
          <span>Seite empfehlen</span>
        </div>
        <div className={styles.infoIcons}>
          {shareLinks.map((link, index) => (
            <a href={link.link} target="_blank" rel="noopener noreferrer" key={index}>
              {link.icon}
            </a>
          ))}
        </div>
        <div className={styles.backToTopButtonContainer}>
          <BackToTopButton link={backToToplink} />
        </div>
      </div>
    </div>
  );
};

InfoSection.propTypes = {
  backToToplink: PropTypes.string
};

export default InfoSection;
