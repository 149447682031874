import React from 'react';

import themesImage from '../images/themes_icon.png';
import documentImage from '../images/document_icon.png';
import productsImage from '../images/products_icon.png';

const infoSectionColumns = [
  {
    title: 'Themenwelten',
    image: <img src={themesImage} style={{ height: 28 }} alt="Themenwelten" />,
    links: [
      {
        title: 'Vorsorgen',
        link:
          '//vorsorgeplaner.online?utm_source=about.convela.cloud&utm_campaign=footer'
      },
      {
        title: 'Zentralregister',
        link: ''
      },
      {
        title: 'Bestatten & Abschiede planen',
        link:
          '//abschiedsplaner.online?utm_source=about.convela.cloud&utm_campaign=footer'
      },
      {
        title: 'Trauern',
        link: ''
      },
      {
        title: 'Kondolieren',
        link: ''
      },
      {
        title: 'Gedenken',
        link: '//gedenkseiten.de'
      },
      {
        title: 'Informieren',
        link: ''
      }
    ]
  },
  {
    title: 'Vorsorgen',
    image: <img src={documentImage} style={{ height: 28 }} alt="Vorsorgen" />,
    links: [
      {
        title: 'Patientenverfügung',
        link:
          '//vorsorgeplaner.online/kostenlose-vorlagen/patientenverfuegung-vordruck-kostenlos-herunterladen?utm_source=about.convela.cloud&utm_campaign=footer'
      },
      {
        title: 'Vorsorgevollmacht',
        link:
          '//vorsorgeplaner.online/kostenlose-vorlagen/vorsorgevollmacht-vordruck-kostenlos-herunterladen?utm_source=about.convela.cloud&utm_campaign=footer'
      },
      {
        title: 'Betreuungsverfügung',
        link:
          '//vorsorgeplaner.online/kostenlose-vorlagen/betreuungsverfuegung-vordruck-kostenlos-herunterladen?utm_source=about.convela.cloud&utm_campaign=footer'
      },
      {
        title: 'Sorgerechtsverfügung',
        link:
          '//vorsorgeplaner.online/kostenlose-vorlagen/sorgerechtsverfuegung-vordruck-kostenlos-herunterladen?utm_source=about.convela.cloud&utm_campaign=footer'
      },
      {
        title: 'Testamentvorlagen',
        link:
          '//vorsorgeplaner.online/kostenlose-vorlagen/testament-vordruck-kostenlos-herunterladen?utm_source=about.convela.cloud&utm_campaign=footer'
      },
      {
        title: 'Zentralregister',
        link: '//convela.cloud/mandate-register'
      },
      {
        title: 'Mandat anlegen',
        link: '//convela.cloud/login/signup-as-private-customer'
      }
    ]
  },
  {
    title: 'Produkte',
    image: <img src={productsImage} style={{ height: 24 }} alt="Produkte" />,
    links: [
      {
        title: 'Schmuckstücke',
        link: '//convela.cloud/categories/all/3/schmuckstuecke'
      },
      {
        title: 'Erinnerungsstücke',
        link: '//convela.cloud/categories/all/47/erinnerungsstuecke'
      },
      {
        title: 'Trauerflor',
        link: '//convela.cloud/categories/all/11/trauerflor'
      },
      {
        title: 'Kerzen',
        link: '//convela.cloud/categories/all/4/kerzen'
      },
      {
        title: 'Literatur',
        link: '//convela.cloud/categories/all/48/literatur'
      },
      {
        title: 'Charity',
        link: '//convela.cloud/categories/all/49/charity'
      },
      {
        title: 'Online-Trauerfeier',
        link: '//trauerfeier.online/'
      }
    ]
  }
];

export default infoSectionColumns;
